<template>
  <div class="osahan-signup login-page">
    <video loop="" autoplay="" muted="" id="vid">
      <source src="img/bg.mp4" type="video/mp4" />
      <source src="img/bg.mp4" type="video/ogg" />
      Your browser does not support the video tag.
    </video>
    <div
      style="margin-top: -68px"
      class="
        d-flex
        align-items-center
        justify-content-center
        flex-column
        vh-100
      "
    >
      <div class="px-5 col-md-6 ml-auto">
        <div class="px-5 col-10 mx-auto">
          <!-- <h2 class="text-dark my-0">Hello There.</h2> -->
          <h3 class="mb-0">Sign Up</h3>
          <form class="mt-2 mb-4" ref="myForm">
            <div class="form-group">
              <label for="exampleInputName1" class="text-dark">Name</label>
              <input
                required
                v-model="newUser.user_name"
                type="text"
                placeholder="Enter Name"
                class="form-control"
                id="exampleInputName1"
                aria-describedby="nameHelp"
              />
            </div>
            <div class="form-group">
              <label for="exampleInputemail1" class="text-dark"
                >Email Address</label
              >
              <input
                required
                v-model="newUser.email"
                type="email"
                pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"
                placeholder="Enter you email address."
                class="form-control"
                id="exampleInputemail1"
                aria-describedby="numberHelp"
              />
            </div>
            <div class="form-group">
              <label for="exampleInputNumber1" class="text-dark"
                >Mobile Number</label
              >
              <input
                required
                v-model="newUser.phone"
                type="tel"
                placeholder="Enter your mobile number"
                class="form-control"
                id="exampleInputNumber1"
                aria-describedby="numberHelp"
              />
            </div>
            <div class="form-group">
              <label for="exampleInputPassword1" class="text-dark"
                >Password</label
              >
              <input
                required
                v-model="newUser.password"
                type="password"
                pattern=".{6,}"
                placeholder="Enter Password"
                class="form-control"
                id="exampleInputPassword1"
              />
            </div>
            <a v-on:click="signUp" class="btn btn-primary btn-lg btn-block">
              SIGN UP
            </a>
            <!-- <div class="py-2">
                            <a class="btn btn-facebook btn-lg btn-block"><i class="feather-facebook"></i> Connect with Facebook</a>
                        </div> -->
          </form>
        </div>
        <div class="new-acc d-flex align-items-center justify-content-center">
          <a href="login">
            <p class="text-center m-0">Already an account? Sign in</p>
          </a>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      newUser: { user_name: null, phone: null, email: null },
    };
  },
  methods: {
    signUp() {
      console.log("signup")
      var BreakException = {};
      try {
        this.$refs.myForm.forEach((element) => {
          if (
            element.tagName.toLowerCase() == "input" ||
            element.tagName.toLowerCase() == "textarea"
          ) {
            if (!element.validity.valid) {
              element.focus();
              throw BreakException;
            }
          }
        });

        let url = `${this.$store.state.baseURL}/api/register?first_name=${this.newUser.user_name}&email=${this.newUser.email}&password=${this.newUser.password}&phone=${this.newUser.phone}`;
        console.log(url);
        fetch(url, {
          method: "POST",
        })
          .then((response) => {
            if (response.ok) {
              return response.json();
            } else {
              console.log(
                "Server returned " +
                  response.status +
                  " : " +
                  response.statusText
              );
            }
          })
          .then((response) => {
            if (response.response) {
              this.setCurUser(response.data);
            } else {
              if (response.message) alert(response.message);
              if (response.messages) {
                alert(response.messages);
              }
            }
          })
          .catch((err) => {
            console.log(err);
          });
      } catch (error) {
        if (error == BreakException) {console.log("break");}
        else{
        console.log(">> "+error)}
      }
    },
    setCurUser(result) {
      console.log(result);
      this.$store.commit("setUser", result);
      window.localStorage.setItem("jwt", result.token);
      window.localStorage.setItem("curUser", JSON.stringify(result));
      this.$router.push("success_save");
    },
  },
};
</script>